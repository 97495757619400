import React from "react";
import './Career.css';
import { useTranslation } from 'react-i18next';

function Career() {
    const { t, i18n } = useTranslation();

    return (
        <div id='section_career'>
            <div className='career-container'>
                <div className='career-text-container'>
                    <div className='header'>
                        {t('Kariera.1')}
                    </div>
                    <div class="row">
                        <div class='col-sm-6 px-0 aboutus-image'>
                            <img alt='kariera' src={require('./images/career.JPEG')} />
                        </div>
                        <div class='col-sm-6 p-5 aboutus-text-block'>
                            {/*<div class="aboutus-text"><p>{t('KarieraNic.1')}</p></div>*/}
                            <div class="aboutus-zkusenosti text-center">Řidič kamionu C+E</div>
                            <div class="aboutus-logo evatrans"><h1>Koho sháníme?</h1></div>
                            <div class="aboutus-text"><p>Přijmeme řidiče sk. C+E na mezinárodní přepravu. Jezdíme hlavně z ČR do Itálie.</p></div>
                            <div class="aboutus-logo evatrans"><h1>Proč jezdit právě u nás?</h1></div>
                            <div class="aboutus-logo evatrans"><h1>Co můžete očekávat?</h1></div>

                            <br />
                            <div class="aboutus-text"><p>{t('KarieraPopis.1')} <span style={{ color: '#f55a00' }}>+420 602 461 346</span> {t('KarieraPopisNebo.1')} <span style={{ color: '#f55a00' }}>evatrans@c-box.cz</span>.</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Career;
import React from "react";
import './CarFleet.css';
import { useTranslation } from 'react-i18next';

function CarFleet() {
    const { t, i18n } = useTranslation();

    return (
        <div id='section_carfleet'>
            <div className='carfleet-container'>
                <div className='carfleet-text-container'>
                    <div className='header'>
                        {t('Vozovy park.1')}
                    </div>
                    <div class='container'>
                        <div class='row p-3 pt-4'>
                            <div class='col-sm carfleet-box text-center'>
                                <div class="c-image pt-3"><img alt='velkoobjemove-soupravy' src={require('./images/carfleet/carfleet1.PNG')} /></div>
                                <div class="c-name fw-bold">{t('Vozy1.1')}</div>
                                <div class="c-position fw-light">{t('Vozy1Popis1.1')}</div>
                                <div class="c-position fw-light">{t('Vozy1Popis2.1')}</div>
                                <div class="c-position fw-light">{t('Vozy1Popis3.1')}</div>
                                <div class="c-position fw-light">{t('Vozy1Popis4.1')}</div>
                            </div>
                            <div class='col-sm carfleet-box text-center'>
                                <div class="c-image pt-4"><img alt='nakladni-vozy' src={require('./images/carfleet/carfleet2.PNG')} /></div>
                                <div class="c-name fw-bold">{t('Vozy2.1')}</div>
                                <div class="c-position fw-light">{t('Vozy2Popis1.1')}</div>
                                <div class="c-position fw-light">{t('Vozy2Popis2.1')}</div>
                                <div class="c-position fw-light">{t('Vozy2Popis3.1')}</div>
                                <div class="c-position fw-light">{t('Vozy2Popis4.1')}</div>
                            </div>
                            <div class='col-sm carfleet-box text-center'>
                                <div class="c-image pt-4"><img alt='dodavkove-vozy' src={require('./images/carfleet/carfleet3.PNG')} /></div>
                                <div class="c-name fw-bold">{t('Vozy3.1')}</div>
                                <div class="c-position fw-light">{t('Vozy3Popis1.1')}</div>
                                <div class="c-position fw-light">{t('Vozy3Popis2.1')}</div>
                                <div class="c-position fw-light">{t('Vozy3Popis3.1')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarFleet;
import React from "react";
import './Services.css';
import { useTranslation } from 'react-i18next';

function Services() {
    const { t, i18n } = useTranslation();

    return (
        <div id='section_services'>
            <div className='services-container'>
                <div className='services-text-container'>
                    <div className='header'>
                        {t('Sluzby.1')}
                    </div>
                    <div class='container'>
                        <div class='row p-1'>
                            <div class='col-sm text-center'>
                                <div class="p-image"><img alt='mezinarodni-preprava' src={require('./images/services/service1.jpg')} /></div>
                                <div class="p-name fw-bold">{t('Sluzba1.1')}</div>
                                <div class="p-position fw-light pb-5">{t('Sluzba1Text.1')}</div>
                            </div>
                            <div class='col-sm text-center'>
                                <div class="p-image"><img alt='dokladky' src={require('./images/services/service2.jpg')} /></div>
                                <div class="p-name fw-bold">{t('Sluzba2.1')}</div>
                                <div class="p-position fw-light pb-5">{t('Sluzba2Text.1')}</div>
                            </div>
                            <div class='col-sm text-center'>
                                <div class="p-image"><img alt='celovozove-zasilky' src={require('./images/services/service3.jpeg')} /></div>
                                <div class="p-name fw-bold">{t('Sluzba3.1')}</div>
                                <div class="p-position fw-light pb-5">{t('Sluzba3Text.1')}</div>
                            </div>
                            <div class='col-sm text-center'>
                                <div class="p-image"><img alt='spedice' src={require('./images/services/service4.jpeg')} /></div>
                                <div class="p-name fw-bold">{t('Sluzba4.1')}</div>
                                <div class="p-position fw-light pb-5">{t('Sluzba4Text.1')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;
import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HomeSection.css';
import { useTranslation } from 'react-i18next';

function HomeSection() {
  const { t, i18n } = useTranslation();

  return (
    <div className='home-container' id='section_home'>
      {/*<video src='/videos/video-2.mp4' autoPlay loop muted />*/}
      <h1>{t('Homeh1.1')}</h1>
      <p>{t('Homeh2.1')}</p>
      <div className='home-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          {t('HomeButton.1')} <i className='fas fa-shopping-cart' />
        </Button>
        {/*<Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
        >
          OBJEDNAT PŘEPRAVU <i className='fas fa-shopping-cart' />
        </Button>*/}
      </div>
    </div>
  );
}

export default HomeSection;
import React from "react";
import './Slogans.css';
import { useTranslation } from 'react-i18next';

function Slogans() {    
    const { t, i18n } = useTranslation();

    return (
        <>
        <div className='slogans-container'>
            <div class="row p-4">
                <div class='col-sm-4 text-center vertical-line'>
                    <div class="slogan-image"><img alt='rodina-tradice' src={require('./images/slogans/family.png')} /></div>
                    <div class="slogan slogan-padding-bottom">{t('Slogan1.1')}</div>
                </div>
                <div class='col-sm-4 text-center vertical-line'>
                    <div class="slogan-image"><img alt='spolehlivost' src={require('./images/slogans/reliability.png')} /></div>
                    <div class="slogan slogan-padding-bottom">{t('Slogan2.1')}</div>
                </div>
                <div class='col-sm-4 text-center'>
                    <div class="slogan-image"><img alt='osobni-pristup' src={require('./images/slogans/personal.png')} /></div>
                    <div class="slogan">{t('Slogan3.1')}</div>
                </div>
            </div >
        </div >
        </>
    )
}

export default Slogans;
import React from "react";
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className='footer-container'>
                <div class='container'>
                    <div class='row'>
                        <div class='col-sm text-center'>
                            <Link class='social-icon-link' to={{ pathname: "https://www.instagram.com/jindrich.broz_evatrans/" }} target="_blank" aria-label='Instagram'>
                                <i class='fab fa-instagram' />
                            </Link>
                        </div>
                        <div class='col text-center pt-2'>
                            <div class="pfooter">+420 602 461 346</div>
                            <div class="pfooter fw-light pb-5">evatrans@c-box.cz</div>
                        </div>
                        <div class='col text-center pt-2'>
                            <div class="pfooter">Jindřich Brož - EVATRANS</div>
                            <div class="pfooter fw-light pb-5">Těmice 146, 394 96 Těmice</div>
                        </div>
                        <div class='col text-center pt-2'>
                        </div>
                        <div class='col text-center pt-2'>
                            <div class="pfooter"></div>
                            <div class="pfooter fw-light pb-5">© Jindřich Brož - EVATRANS</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="pfooter-mobile">
                    +420 602 461 346
                    </div>
                    <div class="pfooter-mobile">
                    evatrans@c-box.cz
                    </div>
                    <div class="pfooter-mobile-copyright">
                    © 2023 Jindřich Brož - EVATRANS
                    </div>
                </div>
        </div>
    )    
}

export default Footer;
import React from 'react';
import '../../App.css';
import HomeSection from '../HomeSection';
import Slogans from '../Slogans';
import AboutUs from '../AboutUs';
import Services from '../Services';
import CarFleet from '../CarFleet';
import Order from '../Order';
import Career from '../Career';
import Contact from '../Contact';
import References from '../References';
import Footer from '../Footer';

function Home () {
    return  (
        <>
            <HomeSection />
            <Slogans />
            <AboutUs />
            <Services />
            <Order />
            <CarFleet />
            <Career />
            <Contact />
            <References />
            <Footer />
        </>
    );
}

export default Home;
import React from "react";
import './References.css';
import { useTranslation } from 'react-i18next';

function References() {
    const { t, i18n } = useTranslation();

    return (
        <div className='references-container'>
            <div className='references-text-container'>
                <div className='header'>
                {t('Reference.1')}
                </div>
                    <div class='row p-4'>
                        <div class='col col-mobile-reference middle'>
                            <img class="img" alt='sud-logo' src={require('./images/references/sud-logo.png')} />
                        </div>
                        <div class='col col-mobile-reference middle'>
                            <img class="img" alt='transforwarding-logo' src={require('./images/references/transforwarding-logo.jpg')} />
                        </div>
                        <div class='col col-mobile-reference middle'>
                            <img class="img" alt='storch-logo' src={require('./images/references/storch-logo.jpg')} />
                        </div>
                        <div class='col col-mobile-reference middle'>
                            <img class="img" alt='japo-logo' src={require('./images/references/japo-logo.png')} />
                        </div>
                        <div class='col col-mobile-reference middle'>
                            <img class="img" alt='fertilis-logo' src={require('./images/references/fertilis-logo.png')} />
                        </div>
                        <div class='col col-mobile-reference middle'>    
                            <img class="img" alt='wohler-logo' src={require('./images/references/stehno-logo.jpg')} />
                        </div>
                        <div class='col col-mobile-reference middle'>    
                            <img class="img" alt='bohemia-spedition-logo' src={require('./images/references/bohemiaspedition-logo.jpg')} />
                        </div>
                        <div class='col col-mobile-reference middle'>
                            <img class="sudlogo" alt='forto-logo' src={require('./images/references/forto-logo.jpeg')} />
                        </div>
                    </div>
            </div>
        </div>
        )    
    }
    
export default References;
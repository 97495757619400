import React from "react";
import './Contact.css';
import { useTranslation } from 'react-i18next';

function Contact() {
    const { t, i18n } = useTranslation();

    return (
        <div id='section_contact'>
            <div className='contacts-container'>
                <div className='contacts-text-container'>
                    <div className='header'>
                        {t('Kontakt.1')}
                    </div>
                    <div class='container'>
                        <div class='row'>
                            <div class='col-sm text-center'>
                                <div class="evatrans fw-bold">
                                    {t('KontaktProvozniDoba.1')}
                                </div>
                                Po - Pá 8:00 - 18:00
                            </div>
                        </div>
                        <div class='row p-3 pt-4'>
                            <div class='col-sm phone fw-bold'>
                                <i class='fa fa-phone' />
                                +420 602 461 346
                            </div>
                            <div class='col-sm email fw-bold'>
                                <i class='fa fa-envelope' />
                                evatrans@c-box.cz
                            </div>
                        </div>
                        <div class='row p-3'>
                            <div class='col-sm text-center address address-box'>
                                <div class="evatrans fw-bold">
                                    Jindřich Brož - EVATRANS <br />
                                </div>
                                <div class="icdic">
                                    IČ: 63893207 <br />
                                    DIČ: CZ7006151493
                                </div>
                            </div>
                            <div class='col-sm text-center address address-box'>
                                <div class="evatrans fw-bold">
                                    {t('KontaktSidlo.1')}<br />
                                </div>
                                Těmice 146<br />
                                394 96 Těmice
                            </div>
                            <div class='col-sm text-center address'>
                                <div class="evatrans fw-bold">
                                    {t('KontaktProvozovna.1')}<br />
                                </div>
                                Těmice 162<br />
                                394 96 Těmice
                            </div>
                        </div>
                        <div class='row p-5'>
                            <div class='col-sm text-center person-box'>
                                <div class="p-image"><i class='fas fa-user-circle' /></div>
                                <div class="p-name fw-bold">Jindřich Brož</div>
                                <div class="p-position fw-light">{t('KontaktMajitel.1')}</div>
                                <div class="p-phone font-weight-light">+420 602 461 346</div>
                                <div class="p-email font-weight-light">evatrans@c-box.cz</div>
                            </div>
                            <div class='col-sm text-center person-box'>
                                <div class="p-image"><i class='fas fa-user-circle' /></div>
                                <div class="p-name fw-bold">Eva Brožová</div>
                                <div class="p-position fw-light">{t('KontaktDispecerka.1')}</div>
                                <div class="p-phone fw-normal">+420 724 166 167</div>
                                <div class="p-email fw-normal">evatrans@c-box.cz</div>
                            </div>
                            <div class='col-sm text-center person-box'>
                                <div class="p-image"><i class='fas fa-user-circle' /></div>
                                <div class="p-name fw-bold">Ing. Jakub Brož</div>
                                <div class="p-position fw-light">{t('KontaktFakturant.1')}</div>
                                <div class="p-phone fw-normal">+420 720 549 590</div>
                                <div class="p-email fw-normal">broz.jakub@evatrans.cz</div>
                            </div>
                            <div class='col-sm text-center'>
                                <div class="p-image"><i class='fas fa-user-circle' /></div>
                                <div class="p-name fw-bold">Mgr. Jindřich Brož</div>
                                <div class="p-position fw-light">{t('KontaktPravnik.1')}</div>
                                <div class="p-phone fw-normal">+420 720 415 387</div>
                            </div>
                        </div>
                    </div>
                    <iframe style={{ width: "100%", height: "500px" }} id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2598.890190252929!2d15.051701933977657!3d49.354226907543136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470cc27c5f1dadb5%3A0x5911f1b326bed1d7!2zVMSbbWljZSAxNjIsIDM5NCA5NiBUxJttaWNl!5e0!3m2!1scs!2scz!4v1666686665689!5m2!1scs!2scz" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    )
}

export default Contact;
import React from "react";
import './AboutUs.css';
import { useTranslation } from 'react-i18next';

function AboutUs() {
    const { t, i18n } = useTranslation();

    return (
        <div id='section_aboutus'>
            <div className='aboutus-container'>
                <div className='aboutus-text-container'>
                    <div className='header'>
                        {t('O nas.1')}
                    </div>
                    <div class="row">
                        <div class='col-sm-6 px-0 aboutus-image'>
                            <img alt='o-nas' src={require('./images/aboutus.JPG')} />
                        </div>
                        <div class='col-sm-6 px-5 aboutus-text-block'>
                            <div class='aboutus-logo evatrans'><h1>{t('Onash1.1')}</h1></div>
                            <div class="aboutus-zkusenosti">{t('Onash2.1')}</div>
                            <div class="aboutus-text"><p>{t('OnasText.1')}</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;
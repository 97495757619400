import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Order.css';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

function Order() {
    const { t, i18n } = useTranslation();
    const {
        register, 
        formState: {errors},
        handleSubmit,
        reset
    } = useForm();
    
    const form = useRef();
    const sendEmail = (formData) => {
        emailjs.send(
            'service_9q1l2ur',
            'template_uodo6ej',
            formData,
            'WOhteYqTFUXQbUBK_'
        )
            .then((result) => {
                console.log(result.text);
                console.log("Email odeslan.");
                alert("Email s poptávkou byl odeslán.");
            }, (error) => {
                console.log(error.text);
                console.log("Email nebyl odeslan.");
                alert("Email s poptávkou se nepodařilo odeslat.");
            });
        reset();
    };
    /*const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_9q1l2ur',
            'template_uodo6ej',
            form.current,
            'WOhteYqTFUXQbUBK_'
        )
            .then((result) => {
                console.log(result.text);
                console.log("Email odeslan.");
            }, (error) => {
                console.log(error.text);
                console.log("Email nebyl odeslan.");
            });
            e.target.reset();
    };*/

    return (
        <div id='section_order'>
            <div className='order-container'>
                <div className='order-text-container'>
                    <div className='header'>
                        {t('Objednavkah1.1')}
                    </div>
                    <div className='container'>
                        <div class="row p-1">
                            <div class="order-text"><p>{t('ObjednavkaText.1')}</p></div>
                            <div class='col-sm-6 px-5 order-form'>
                                <form 
                                    autoComplete="off" 
                                    ref={form} 
                                    onSubmit={handleSubmit(sendEmail)}
                                >
                                    <div class="row">
                                        <div class="form-group order-form-input">
                                            <label for="inputName" class="fw-bold order-form-label">{t('ObjednavkaFormJmeno.1')} <span class="p-evatrans">*</span></label>
                                            <input {...register('user_name', {
                                                required: "Jméno a příjmení/název společnosti jsou povinná pole!"
                                            })} type="text" class="form-control" name="user_name" />
                                            <span class="form-error">{errors.user_name?.message}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group order-form-input">
                                            <label for="inputEmail" class="fw-bold order-form-label">{t('ObjednavkaFormEmail.1')} <span class="p-evatrans">*</span></label>
                                            <input {...register('user_email', {
                                                required: "Email je povinné pole!",
                                                pattern: {
                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: 'Prosím vyplňte validní email!',
                                                },
                                            })} type="text" class="form-control" name="user_email" />
                                            <span class="form-error">{errors.user_email?.message}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group order-form-input">
                                            <label for="inputPhone" class="fw-bold order-form-label">{t('ObjednavkaFormTelefon.1')}</label>
                                            <input {...register('user_phone', {
                                                minLength: {
                                                    value: 9,
                                                    message: "Telefonní číslo by mělo obsahovat alespoň 9 číslic!"
                                                },
                                                maxLength: {
                                                    value: 13,
                                                    message: "Telefonní číslo by s předčíslím nemělo obsahovat více než 13 znaků!"
                                                }
                                            })} type="phone" class="form-control" name="user_phone" />
                                            <span class="form-error">{errors.user_phone?.message}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group order-form-input">
                                            <label for="exampleFormControlTextarea1" class="fw-bold order-form-label">{t('ObjednavkaFormPopis.1')} <span class="p-evatrans">*</span></label>
                                            <textarea {...register('user_message', {
                                                required: "Popis poptávané přepravy je povinné pole!"
                                            })}  class="form-control" name="user_message" rows="6" placeholder={t('ObjednavkaFormPopisPlaceholder.1')} />
                                            <span class="form-error">{errors.user_message?.message}</span>
                                        </div>
                                    </div>
                                    <div class="order-form-input order-form-label fst-italic"><span class="p-evatrans">*</span> {t('ObjednavkaFormHvezdicka.1')}</div>
                                    <div class="order-form-button">
                                        <button type="submit" class="btn-evatrans color-white">{t('ObjednavkaFormButton.1')}</button>
                                    </div>
                                </form>
                            </div>
                            <div class='col-sm-6 order-image'>
                                <img alt='nezavazna-objednavka' src={require('./images/order7.jpg')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Order;
import React, {useState} from "react";
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const { t, i18n } = useTranslation();

  function handleTranslate(lang) {
    i18n.changeLanguage(lang);
  }

  return (
    <>
        <nav className="navbar">
            <div className="navbar-container-left">
                <HashLink to="/#section_home" className="navbar-logo" onClick={closeMobileMenu}>
                </HashLink>
                <div class="navbar-slogan">{t('Slogan.1')}</div>
            </div>
            <div className="navbar-container-right">
                <div className='menu-icon' onClick={handleClick}>
                  <i className={click? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click? 'nav-menu active' : 'nav-menu'}>
                  <li className='nav-item'>
                    <HashLink to='/#section_aboutus' className='nav-links' onClick={closeMobileMenu}>
                      {t('O nas.1')}
                    </HashLink>
                  </li>
                  <li className='nav-item'>
                    <HashLink to='./#section_services' className='nav-links' onClick={closeMobileMenu}>
                    {t('Sluzby.1')}
                    </HashLink>
                  </li>
                  <li className='nav-item'>
                    <HashLink to='./#section_order' className='nav-links' onClick={closeMobileMenu}>
                    {t('Objednavka.1')}
                    </HashLink>
                  </li>
                  <li className='nav-item'>
                    <HashLink to='/#section_carfleet' className='nav-links' onClick={closeMobileMenu}>
                    {t('Vozovy park.1')}
                    </HashLink>
                  </li>
                  <li className='nav-item'>
                    <HashLink to='/#section_career' className='nav-links' onClick={closeMobileMenu}>
                    {t('Kariera.1')}
                    </HashLink>
                  </li>
                  <li className='nav-item'>
                    <HashLink to='/#section_contact' className='nav-links' onClick={closeMobileMenu}>
                    {t('Kontakt.1')}
                    </HashLink>
                  </li>
                </ul>
                <div class="navbar-button-flag">
                  <button onClick={()=>handleTranslate('cz')}>
                    <img alt='flag-cze' src={require('./images/flag_cze.png')} />
                  </button>
                  <button onClick={()=>handleTranslate('en')}>
                    <img alt='flag-eng' src={require('./images/flag_eng.png')} />
                  </button>
                  <button onClick={()=>handleTranslate('it')}>
                    <img alt='flag-ita' src={require('./images/flag_ita.png')} />
                  </button>
                </div>
            </div>
        </nav>
    </>
  )
}

export default Navbar
